import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 按模块导入路由
// import DemoRouters from './demo-router'
import Lvya from './lvya'

export default new Router({
    // mode: 'history', //后端支持可开
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        // ...DemoRouters,
        ...Lvya
    ]
})
