import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App'
import router from './router'
import store from './store'
import 'swiper/dist/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import less from 'less'
import 'animate.css'
import VideoPlayer from 'vue-video-player'
import VueParticles from 'vue-particles'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VueAwesomeSwiper)
Vue.use(less)
Vue.use(VideoPlayer)
Vue.use(VueParticles)

import { BaiduMap } from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ay: 'lR8futQyqsOmiEYw35VOSxyUVUbjGXLn'
})

// 通用指令过滤器 指令
// import '../../lvya-common/src/service/directive/index'
// import '../../lvya-common/src/service/filter/index'
// 通用工具类

// api统一成全局 访问
// import ApiAdmin from './edu-common/src/api/admin/index'
// window.ApiAdmin = ApiAdmin

// 导入flex布局
// import './edu-common/src/css/flex.css'

import Cookies from 'js-cookie'
// 配置成全局使用
window.Cookies = Cookies

Vue.use(ElementUI)
// VueInstance可以全局使用
window.VueInstance = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
