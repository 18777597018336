<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {}
        }
    }
</script>

<style lang="less">
    @import "less/frontend.less";

    #app {
    }
</style>
