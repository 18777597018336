// 切換動畫

const state = {
  isLoading: false, // 路由切换 加载
  ajaxLoadingFinished: false, // ajax 异步加载是否结束
  _url_: window.location.href,
  direction: 'forward',
  canRenderContent: true, // 重复点击左侧导航刷新页面处理
  isNavCollapse: false // 导航收起和展开
}

const mutations = {
  updateLoadingStatus(state, payload) {
    state.isLoading = payload.isLoading
  },
  updateDirection(state, payload) {
    state.direction = payload.direction
  },
  ajaxLoadingFinished(state, res) {
    state.ajaxLoadingFinished = res
  },
  canRenderContent(state, res) {
    state.canRenderContent = res
  },
  isNavCollapse(state, res) {
    state.isNavCollapse = res
  }
}

export default {
  state,
  mutations
}
