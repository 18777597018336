export default [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/',
        component: () => import('@/views/lvya/index'),
        children: [
          {
            path: '/home',
            component: () => import('@/views/lvya/home/index'),
          },
          {
            path: '/index',
            component: () => import('@/views/lvya/home/index'),
          },
          {
            path: '/schoolFzpt',
            component: () => import('@/views/lvya/schoolFzpt/index'),
          },
          {
            path: '/studentGrowth', // 学校发展平台-学生成长
            component: () => import('@/views/lvya/schoolFzpt/studentGrowth'),
          },
          {
            path: '/teacherDevelopment', // 学校发展平台-教师发展
            component: () => import('@/views/lvya/schoolFzpt/teacherDevelopment'),
          },
          {
            path: '/developmentPlanning', // 学校发展平台-教师发展
            component: () => import('@/views/lvya/schoolFzpt/developmentPlanning'),
          },
          {
              path: '/dataCenter',
              component: () => import('@/views/lvya/basicPlatform/dataCenter.vue')
          },
          {
              path: '/businessCenter',
              component: () => import('@/views/lvya/basicPlatform/businessCenter.vue')
          },
          {
              path: '/schoolBigData',
              component: () => import('@/views/lvya/basicPlatform/schoolBigData.vue')
          },
          {
            path: '/ddmpt',
            component: () => import('@/views/lvya/ddmpt/index.vue')
          },
          {
            path: '/solution', // 区校一体化解决方案
            component: () => import('@/views/lvya/solution/solution.vue')
          },
          {
            path: '/jzjx', // 精准教学
            component: () => import('@/views/lvya/jzjx/jzjx.vue')
          },
          {
            path: '/aboutUs',
            component: () => import('@/views/lvya/aboutUs/index.vue')
          },
          {
            path: '/certificate', // 证书资质
            component: () => import('@/views/lvya/certificate/certificate.vue')
          },
          {
            path: '/join', // 代理加盟
            component: () => import('@/views/lvya/join/join.vue')
          },
          {
            path: '/news', // 新闻动态
            component: () => import('@/views/lvya/news/news.vue')
          },
          {
            path: '/newsDetails', // 新闻动态详情
            component: () => import('@/views/lvya/news/newsDetails.vue')
          },
          {
            path: '/appCenter', // 应用中心
            component: () => import('@/views/lvya/app/appCenter.vue')
          },
          {
            path: '/appCenterDetails', // 应用中心详情
            component: () => import('@/views/lvya/app/appCenterDetails.vue')
          },
          {
            path: '/zhgl', // 应用中心详情
            component: () => import('@/views/lvya/zhgl/index.vue')
          },
          {
            path: '/nhsfz', // 内涵式发展
            component: () => import('@/views/lvya/nhsfz/index.vue')
          },
          {
            path: '/kfszhxy', // 开放式智慧校园
            component: () => import('@/views/lvya/solution/kfszhxy.vue')
          },
        ]
    },
]
